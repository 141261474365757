import { IUserProfile } from 'types/auth';
import { Scene } from 'types/scene';
import { EditGroup } from 'types/editGroup';
import { Media, MediaType } from 'types/media';
import { Template } from 'types/template';
import { Permission } from 'types/permission';
import { Space } from 'types/space';
import { Comment } from 'types/comment';
import { GroupVisitorData, SceneVisitorData } from 'api/report';

import { mergeDeepWith } from 'ramda';

// type NotNullable<T> = T extends null | undefined ? never : T;
import { getRandom } from 'utils';

type Models =
    | IUserProfile
    | Scene
    | EditGroup
    | Media
    | Template
    | GroupVisitorData
    | SceneVisitorData
    | Permission
    | Comment;

export const getSpaceModel = (): Space => ({
    id: getRandom(7),
    name: '',
    description: '',
    thumbnailUrl: '',
    category: [] as number[],
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
});

export const getTemplateModel = (): Template => ({
    id: getRandom(7),
    ownerId: getRandom(7),
    spaceId: getRandom(7),
    space: getSpaceModel(),
    type: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
});

export const getUserModel = (): IUserProfile => ({
    id: getRandom(7),
    email: '',
    lastName: '',
    firstName: '',
    profileUrl: '',
    companyName: '',
    phone: '',
    position: '',
    username: '',
});

export const getSceneModel = (): Scene => ({
    backgroundMusicPlay: false,
    backgroundMusicUrls: [],
    contactDisplay: false,
    shareDispaly: false,
    logoDisplay: false,
    thumbnailUrl: '',
    contactEmail: '',
    contactPosition: '',
    description: '',
    contactName: '',
    phoneNumber: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    space: getSpaceModel(),
    owner: getUserModel(),
    logoUrl: '',
    status: '',
    permission: 'member',
    name: '',
    id: getRandom(7),
    hashtags: [],
    groupId: getRandom(7),
    editGroups: [],
    booths: [],
    groupAreas: [],
    introVideoPlay: false,
    introVideoTitle: '',
    introVideoUrl: '',
    likeNum: 0,
    isMp: false,
    glbUrl: '',
});

export const getEditGroupModel = (): EditGroup => ({
    id: getRandom(7),
    sceneId: getRandom(7),
    name: '',
    logoUrl: '',
    maxTag: 0,
    maxUser: 0,
    createdAt: '',
    deletedAt: '',
    users: [],
    booths: [],
});

export const getMediaModel = (): Media => ({
    id: getRandom(7),
    owner: getUserModel(),
    type: '' as unknown as MediaType,
    name: '',
    fileUrl: '',
    usdzUrl: '',
    thumbnailUrl: '',
    linkName: '',
    linkUrl: '',
    openType: 'iframe',
    unclickable: false,
    visible: true,
    followCamera: false,
    isVertical: false,
    searchable: false,
    defaultWidth: 1,
    description: '',
    createdAt: '',
    deletedAt: '',
    updatedAt: '',
    ctaName: '',
    attributes: {
        imageUrls: [],
        videoUrl: '',
    },
});

export const getGroupVisitorDataModel = (): GroupVisitorData => ({
    name: '',
    email: '',
    country: '',
    company: '',
    position: '',
    stayTime: '',
});

export const getSceneVisitorDataModel = (): SceneVisitorData => ({
    name: '',
    email: '',
    country: '',
    company: '',
    position: '',
    role: '',
    username: '',
    id: getRandom(7),
});

export const getPermissionModel = (): Permission => ({
    accountId: '',
    expiredAt: '',
    features: [],
    id: '',
    maxLiveTours: 0,
    name: '',
    permits: null,
    token: '',
    tokenExpiredAt: '',
    usage: false,
});

export const getCommentModel = (): Comment => ({
    id: '',
    sceneId: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    createdAt: '',
    message: '',
});

const mergeRule = <T>(defaultValue: T, value: T) => {
    if (value == null) return defaultValue;
    return value;
};
export const withDefault =
    <T extends Models>(getModel: () => T) =>
    (data?: T): T => {
        const model = getModel();
        return mergeDeepWith(mergeRule)(model, data || ({} as T));
    };
export const removeRawData = (data: any, deleteId = false) => {
    const result = { ...data };
    if (deleteId) {
        const isId = /id$/i;
        Object.keys(result).forEach((property) => {
            if (isId.test(property)) {
                delete result[property];
            }
        });
    }
    delete result.createdAt;
    delete result.deletedAt;
    delete result.updatedAt;
    return result;
};
